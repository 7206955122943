@import "legacy/branding";

.btn-group-vertical {width:100%; border:5px solid white; margin-top:-20px; float: right; box-shadow:0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
	.btn-primary{ border:none; border-radius:0; padding: 15px 0; font-size:18px; border-top-right-radius:0 !important; border-bottom-left-radius:0 !important;
		i {float:left; font-size:xx-large; margin-left:25px; line-height:0.8;}
		&:nth-child(2){background:$brandcolor1; margin:5px 0;
			&:hover, &:focus{background:$brandcolor1-dark;}
		}
		&:nth-child(3){background:$brandcolor2;
			&:hover, &:focus{background:$brandcolor2-dark;}
		}
	}
}

.callouts {margin-top:50px;
	h2 { text-align:center;
		span{color:$gray-mid3; font-size:small;}
	}
	.thumbnail { margin-bottom:65px;
		.row {text-align:center;
			h5 {
				span{font-size: -webkit-xxx-large;}
			}
		}
	}
	.btn-primary{background:$brandcolor2; border-color:$brandcolor2; border-radius:0; font-size:22px; margin-bottom:-44px;
		&:hover, &:focus{background:$brandcolor2-dark;}
	}
}

.card {
	// height:200px;
	// width:200px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	margin-bottom:15px;
	width:100%;
	.table {display:none;}
	&:hover, &:focus {
		// background-color:$gray-white1;
		.table{display:table; height:100%; margin-bottom:0;
			.table-cell{display:table-cell; height:100%; vertical-align:middle;
				h4{text-align:center; color:white;}
			}
		}
	}
}

@media (min-width:480px) and (max-width:767px) {
	.btn-group-vertical{padding:0 30px;}
	.callouts{
		.thumbnail {width: 80%; margin: auto auto 65px;}
	}
}

